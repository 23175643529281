
import Logo from "@/components/Logo/index.vue";
import { Component, Vue, Watch } from "vue-property-decorator";
import { Route } from "vue-router";
import { Dictionary } from "vue-router/types/router";
import { Form as ElForm, Input } from "element-ui";
import store from "@/store";

type ForgotFormData = { emailAddress: string };
type ResetFormData = { emailAddress: string; passwordResetToken: string; newPassword: string; confirmPassword: string };

@Component({
  name: "ForgotPassword",
  components: {
    Logo,
  },
})
export default class extends Vue {
  validateEmail = (rule: any, value: string, callback: Function) => {
    if (value.length < 1) {
      callback(new Error("Email is required"));
    } else {
      callback();
    }
  };

  validatePassword = (rule: any, value: string, callback: Function) => {
    if (value.length < 1) {
      callback(new Error("Password is required"));
    } else if (value.length < 8) {
      callback(new Error("The password can not be less than 8 digits"));
    } else {
      callback();
    }
  };

  validateConfirmPassword = (rule: any, value: string, callback: Function) => {
    if (this.resetPasswordForm.newPassword !== this.resetPasswordForm.confirmPassword) {
      callback(new Error("The passwords do not match"));
    } else {
      callback();
    }
  };
  forgotPasswordForm: ForgotFormData = {
    emailAddress: "",
  };
  resetPasswordForm: ResetFormData = {
    emailAddress: "",
    passwordResetToken: "",
    newPassword: "",
    confirmPassword: "",
  };
  forgotPasswordRules = {
    emailAddress: [{ validator: this.validateEmail, trigger: "blur" }],
  };
  resetPasswordRules = {
    emailAddress: [{ validator: this.validateEmail, trigger: "blur" }],
    newPassword: [{ validator: this.validatePassword, trigger: "blur" }],
    confirmPassword: [{ validator: this.validateConfirmPassword, trigger: "blur" }],
  };
  done = false;
  code = "";
  passwordType = "password";
  confirmPasswordType = "password";
  loading = false;
  showDialog = false;
  redirect?: string;
  otherQuery: Dictionary<string> = {};

  @Watch("$route", { immediate: true })
  onRouteChange(route: Route) {
    // TODO: remove the "as Dictionary<string>" hack after v4 release for vue-router
    // See https://github.com/vuejs/vue-router/pull/2050 for details
    const query = route.query as Dictionary<string>;
    if (query) {
      this.redirect = query.redirect;
      this.otherQuery = this.getOtherQuery(query);
    }
  }

  created() {
    if (this.$route.query.email) {
      this.resetPasswordForm.emailAddress = this.$route.query.email as string;
    }

    if (this.$route.query.code) {
      this.code = decodeURI(this.$route.query.code as string);
    }
  }

  mounted() {
    if ((this.code && this.resetPasswordForm.emailAddress === "") || (!this.code && this.forgotPasswordForm.emailAddress === "")) {
      (this.$refs.emailAddress as Input).focus();
    }
  }

  showPwd(isConfirm: boolean) {
    if (!isConfirm) {
      if (this.passwordType === "password") {
        this.passwordType = "";
      } else {
        this.passwordType = "password";
      }
    } else {
      if (this.confirmPasswordType === "password") {
        this.confirmPasswordType = "";
      } else {
        this.confirmPasswordType = "password";
      }
    }
    this.$nextTick(() => {
      if (!isConfirm) {
        (this.$refs.newPassword as Input).focus();
      } else {
        (this.$refs.confirmPassword as Input).focus();
      }
    });
  }

  async handleForgotPassword() {
    (this.$refs.forgotPasswordForm as ElForm).validate(async (valid: boolean) => {
      if(valid) {
        this.loading = true;
        await store
          .dispatch("auth/forgotPassword", { emailAddress: this.forgotPasswordForm.emailAddress })
          .then((res) => {
            this.loading = false;
            this.$message({ showClose: true, type: "success", duration: 2000, message: "Reset password email sent!" });
            this.done = true;
          })
          .catch((error) => {
            this.loading = false;
            // this.$message({showClose: true, type: 'error', duration: 2000, message: 'Invalid email or password.'})
          });
        }
    });
  }

  async handleResetPassword() {
    (this.$refs.resetPasswordForm as ElForm).validate(async (valid: boolean) => {
      if(valid) {
        this.loading = true;
        await store
          .dispatch("auth/resetPassword", { emailAddress: this.resetPasswordForm.emailAddress, passwordResetToken: this.code, newPassword: this.resetPasswordForm.newPassword })
          .then((res) => {
            this.loading = false;
            this.$message({ showClose: true, type: "success", duration: 2000, message: "Password reset!" });
            this.$router.push({ name: "Login", query: {email: this.resetPasswordForm.emailAddress} });
          })
          .catch((error) => {
            this.loading = false;
            // this.$message({showClose: true, type: 'error', duration: 2000, message: 'Invalid email or password.'})
          });
        }
    });
  }

  getOtherQuery(query: Dictionary<string>) {
    return Object.keys(query).reduce((acc, cur) => {
      if (cur !== "redirect") {
        acc[cur] = query[cur];
      }
      return acc;
    }, {} as Dictionary<string>);
  }
}
